import React from "react"

import Layout from "../components/Layout"
import Content from "../components/Content"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Content>
      <SEO title="404: nie znaleziono" />
      <h1>Strona nie znaleziona</h1>
      <p>Właśnie wybrałeś stronę, która nie istnieje. Wybierz inną z poziomu nawigacji.</p>
    </Content>
  </Layout>
)

export default NotFoundPage
